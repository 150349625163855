.item-section {
  &__description {
    padding-bottom: 0;
    font-family: $helvetica;

    // stylelint-disable max-nesting-depth
    p {
      padding: 0;
      margin: 0 0 10px;
      white-space: pre-wrap;
    }

    ul {
      padding: 12px 0;
      margin: 0;

      li {
        font-size: 15px;
        line-height: 1.44;
        font-weight: 400;
        color: #000;
        list-style: none;

        &::before {
          content: '';
          width: 11px;
          height: 11px;
          float: left;
          margin: 5px 8px 0 0;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url('/static/images/check-symbol.svg');
        }
      }

      &:first-of-type {
        padding: 0;
        border-bottom: 2px solid #f4f4f4;
        margin-bottom: 10px;

        li {
          font-weight: 700;
          margin-bottom: 5px;
          display: inline-block;
          width: 48%;
          vertical-align: top;
          margin-right: 0.5%;
          position: relative;
          padding-left: 14px;
          box-sizing: border-box;

          &::before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #936447;
            float: left;
            margin: 7px 10px 0 0;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
  }

  &__residences {
    margin-bottom: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #f6f4f1;

    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}
