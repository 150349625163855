.svg-icon {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);

  &--white {
    fill: #fff;
  }

  &--blue {
    fill: #4264fd;
  }

  &--red {
    fill: #ff3c00;
  }

  &--pink {
    fill: #ffe6e5;
  }

  &--indent {
    margin-right: 5px;
  }

  &--brown {
    fill: #98918a;
  }

  &--dark {
    fill: #2f1f19;
  }
}
