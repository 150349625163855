.additional-info {
  &__i {
    $icons: internal_loan, mortgage, promotion;

    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #98918a;
    display: flex;
    align-items: center;
    font-family: $helvetica;
    position: relative;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-text {
      margin-left: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #98918a;
    }

    &-title {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #2f1f19;
    }

    @each $icon in $icons {
      &.#{$icon}::before {
        content: '';
        display: block;
        width: 26px;
        height: 30px;
        background: url('/static/images/residences/#{$icon}.svg')
          no-repeat
          center;
        background-size: contain;
      }
    }
  }
}
