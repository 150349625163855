$background-color: #f6f4f1;
$grey-color: #eae5e0;
$text-color: #98918a;
$link-color: #2f1f19;
$text-font-size: 12px;
$link-font-size: 14px;

.footer {
  background-color: $background-color;
  padding: 0 15px;
  margin-top: auto;
  border-top: solid 1px $grey-color;

  &--with-indent {
    padding-bottom: 40px;
  }

  &--white-background {
    margin-top: 20px;
    background-color: #fff;
  }

  &__blk {
    padding: 25px 0;
    border-bottom: solid 1px $grey-color;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__link {
    font-family: $helvetica;
    font-size: $link-font-size;
    color: $link-color;
    text-decoration: none;

    &--pages {
      display: block;
      padding-bottom: 5px;
      margin-bottom: 10px;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &--contact {
      display: inline-block;
      font-size: 15px;
      font-weight: bold;

      &:first-child {
        margin-right: 20px;
      }
    }

    &--social {
      position: relative;
      display: inline-block;
      width: 37px;
      height: 37px;
      margin-right: 25px;
      border-radius: 50%;
      background-color: $grey-color;

      &_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 17px;
        height: 17px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transform: translate(-50%, -50%);

        &--instagram {
          background-image: url('/static/images/social/instagram.svg');
        }

        &--facebook {
          background-image: url('/static/images/social/facebook.svg');
        }

        &--email {
          background-image: url('/static/images/social/email.svg');
        }
      }
    }

    &--inline {
      display: inline-block;
      margin: 0 15px 20px 0;
      padding: 0;
      background-color: transparent;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__opening-hours {
    margin: 8px 0 25px;
    font-family: $helvetica;
    font-size: $text-font-size;
    color: $text-color;
  }

  &__info,
  &__copyright {
    font-family: $helvetica;
    font-size: $text-font-size;
    color: $text-color;
  }

  &__copyright {
    margin-top: 15px;
  }
}
