.prom-section {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__not-author-container {
    gap: 5px;
    flex-direction: row;
  }

  &__not-author-alignment {
    padding: 15px 0;
  }

  &__item {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-radius: 7px;
    border: 1px solid #eae5e1;
    background-color: #f8f8f8;
    font-family: $helvetica;

    &--not-author {
      padding: 5px 15px;
      min-height: 50px;

      @media screen and (max-width: 350px) {
        padding: 3px 8px;
        min-height: 50px;
      }
    }

    &--not-author-icon {
      align-self: center;

      svg {
        margin-top: -3px;
      }
    }

    &-content {
      color: #2f1f19;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--not-author-content {
        justify-content: center;
      }

      &_title {
        font-size: 16px;
        font-weight: 700;
        margin-right: 10px;
      }

      &--not-author-title {
        font-size: 13px;
        font-weight: 700;

        @media screen and (max-width: 350px) {
          font-size: 10px;
        }
      }

      &--not-author-price {
        color: #4264fd;
        font-size: 11px;
        font-weight: 500;

        @media screen and (max-width: 350px) {
          font-size: 8px;
        }
      }

      svg {
        margin-top: -3px;
      }

      &_desc {
        font-size: 12px;
        font-weight: 400;
      }
    }

    &-button {
      min-width: 100px;
      height: 40px;
      padding: 10px 15px;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background-color: #4264fd;
      color: #fff;
      font-size: 15px;
    }
  }
}
