.item-location {
  &__address {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #2f1f19;
    margin-bottom: 10px;
  }

  &__map {
    background-color: #f0f0f0;
    background-image: url('/static/images/residences/map.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 140px;
    border-radius: 7px;
    overflow: hidden;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #4264fd;
    padding: 10px 15px;
    background: #f6f4f1;
    border-radius: 20px;
    box-sizing: border-box;
    margin-top: 15px;
    margin-right: 5px;
    display: inline-block;
  }
}
