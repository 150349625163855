.residence-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__container {
    padding: 30px 15px;
    margin: 30px -15px 0;
    border-top: 1px solid #eae5e1;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #2f1f19;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #98918a;
    display: flex;
    justify-content: space-between;
  }

  &__row {
    margin: 20px 0;
  }

  &__search {
    select {
      display: inline-block;
      padding-right: 5px;
      text-align-last: right;
      direction: rtl;
      color: #4264fd;
      font-size: 15px;
      outline: none;
      border: 0;
      appearance: none;
      background: transparent;
    }
  }

  &__show-more {
    padding: 15px 35px;
    width: 100%;
    background: #fff;
    border: 1px solid #eae5e1;
    border-radius: 7px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #4264fd;
  }
}
