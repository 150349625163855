.complaint-section {
  display: flex;
  height: 55px;
  padding: 0 15px;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #eae5e1;
  background-color: #fff;

  span {
    color: #2f1f19;
    font-family: $helvetica;
    font-size: 15px;
    text-decoration-line: underline;
  }
}
